//***  OPEN MODAL YAPEE   ***//

const yapeModal = document.getElementById('modalYape');
yapeModal.style.display = 'none';

const openYapeModal = document.getElementById('btnYape');
openYapeModal.addEventListener('click', () => {
	yapeModal.style.display = 'flex';
});

const closeYapeModalBtn = document.getElementById(
	'closeYapeModal'
);
closeYapeModalBtn.addEventListener('click', () => {
	yapeModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === yapeModal) {
		yapeModal.style.display = 'none';
	}
});

//***  OPEN MODAL PLINN   ***//

const plinModal = document.getElementById('modalPlin');
plinModal.style.display = 'none';

const openPlinModal = document.getElementById('btnPlin');
openPlinModal.addEventListener('click', () => {
	plinModal.style.display = 'flex';
});

const closePlinModalBtn = document.getElementById(
	'closePlinModal'
);
closePlinModalBtn.addEventListener('click', () => {
	plinModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === plinModal) {
		plinModal.style.display = 'none';
	}
});

//***  OPEN MODAL Interbank   ***//


const interbankModal = document.getElementById('modalInterbank');
interbankModal.style.display = 'none';

const openInterbankModal = document.getElementById('btnInterbank');
openInterbankModal.addEventListener('click', () => {
	interbankModal.style.display = 'flex';
});

const closeInterbankModalBtn = document.getElementById(
	'closeInterbankModal'
);
closeInterbankModalBtn.addEventListener('click', () => {
	interbankModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === interbankModal) {
		interbankModal.style.display = 'none';
	}
});

//***  OPEN MODAL BCP   ***//

const bcpModal = document.getElementById('modalBCP');
bcpModal.style.display = 'none';

const openBcpModal = document.getElementById('btnBCP');
openBcpModal.addEventListener('click', () => {
	bcpModal.style.display = 'flex';
});

const closeBcpModalBtn = document.getElementById(
	'closeBcpModal'
);
closeBcpModalBtn.addEventListener('click', () => {
	bcpModal.style.display = 'none';
});


window.addEventListener('click', (event) => {
	if (event.target === bcpModal) {
		bcpModal.style.display = 'none';
	}
});
